var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.top1 === 0)?_c('main',[_vm._m(0)]):_vm._e(),(_vm.top1 === 1)?_c('main',[_c('section',{staticClass:"section select result panel-red1 flame-red"},[_vm._m(1),_c('ul',{staticClass:"list"},[_c('li',{staticClass:"item"},[_c('a',{staticClass:"btn flash"},[_c('img',{attrs:{"src":_vm.flashImg1,"width":"450","alt":"１．避けて進む"}})])]),_vm._m(2)]),_vm._m(3)])]):_vm._e(),(_vm.top1 === 2)?_c('main',[_c('section',{staticClass:"section select result panel-red1 flame-red"},[_vm._m(4),_c('ul',{staticClass:"list"},[_vm._m(5),_c('li',{staticClass:"item"},[_c('a',{staticClass:"btn flash"},[_c('img',{attrs:{"src":_vm.flashImg2,"width":"450","alt":"２．レーザーで止める"}})])])]),_vm._m(6)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section select panel-red1 flame-red"},[_c('div',{staticClass:"text fade"},[_c('img',{attrs:{"src":require("@/assets/img/select_text.webp"),"width":"350","alt":"宇宙デブリが接近中！どうしよう？タップして選んで！"}})]),_c('ul',{staticClass:"list fade-turn"},[_c('li',{staticClass:"item"},[_c('a',{staticClass:"btn",attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/img/select_btn1.webp"),"width":"450","alt":"１．避けて進む"}})])]),_c('li',{staticClass:"item"},[_c('a',{staticClass:"btn",attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/img/select_btn2.webp"),"width":"450","alt":"２．レーザーで止める"}})])])]),_c('div',{staticClass:"sorajiro"},[_c('img',{attrs:{"src":require("@/assets/img/shooting_sorajiro.webp"),"width":"320","alt":"そらじろー"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('img',{attrs:{"src":require("@/assets/img/wait_text.webp"),"width":"280","alt":"選択ありがとう！みんなが選んだ行動は…"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('a',{staticClass:"btn inactive"},[_c('img',{attrs:{"src":require("@/assets/img/select_btn2.webp"),"width":"450","alt":"２．レーザーで止める"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sorajiro"},[_c('img',{attrs:{"src":require("@/assets/img/shooting_sorajiro.webp"),"width":"320","alt":"そらじろー"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('img',{attrs:{"src":require("@/assets/img/wait_text.webp"),"width":"280","alt":"選択ありがとう！みんなが選んだ行動は…"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('a',{staticClass:"btn inactive"},[_c('img',{attrs:{"src":require("@/assets/img/select_btn1.webp"),"width":"450","alt":"１．避けて進む"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sorajiro"},[_c('img',{attrs:{"src":require("@/assets/img/shooting_sorajiro.webp"),"width":"320","alt":"そらじろー"}})])
}]

export { render, staticRenderFns }