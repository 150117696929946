<template>
	<div>
		<main v-if="answer === 0">
			<section class="section quiz quiz2-bg panel-purple">
				<div class="text1 fade"><img src="@/assets/img/quiz2_text1.webp" width="350"
						alt="宇宙ホテルで過ごすとしたらどんな状態になる？"></div>
				<div class="sorajiro"><img src="@/assets/img/quiz2_sorajiro.webp" width="150" alt="そらじろー"></div>
				<ul class="list fade-turn">
					<li class="item"><a class="btn" @click="select(1)"><img src="@/assets/img/quiz2_btn1.webp"
								width="450" alt="１．逆さまに寝る"></a></li>
					<li class="item"><a class="btn" @click="select(2)"><img src="@/assets/img/quiz2_btn2.webp"
								width="450" alt="２．ジュースこぼれる"></a></li>
					<li class="item"><a class="btn" @click="select(2)"><img src="@/assets/img/quiz2_btn3.webp"
								width="450" alt="３．座って本を読む"></a></li>
				</ul>
				<div class="text2"><img src="@/assets/img/quiz1_text2.webp" width="200" alt="選択肢をタップ"></div>
			</section>
		</main>
		<!-- 正解 -->
		<main v-if="answer === 1">
			<section class="section right right2-bg panel-purple">
				<div class="result"><img src="@/assets/img/answer_correct.webp" width="360" alt="正解"></div>
				<div class="answer"><img src="@/assets/img/right2_answer.webp" width="420" alt="合言葉：レゴリス"></div>
				<div class="text"><img src="@/assets/img/right2_text.webp" width="300" alt="バスを降りる時に合言葉を２つ言えたらプレゼント">
				</div>
			</section>
		</main>
		<!-- 不正解 -->
		<main v-if="answer === 2 || answer === 3">
			<section class="section wrong wrong2-bg panel-purple">
				<div class="result"><img src="@/assets/img/answer_incorrect.webp" width="400" alt="不正解"></div>
				<div class="answer"><img src="@/assets/img/wrong_sorajiro.webp" width="430" alt="ざんねん"></div>
			</section>
		</main>

	</div>
</template>
<style scoped>
@import "../../css/style.css";
</style>
<script>
import MooxApi from '../../MooxApi';

import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');

export default {
	name: "Quiz2",
	components: {

	},
	data() {
		return {
			choice: 0,
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			answer: 0,
		};
	},
	async mounted() {
		console.log("moutend Quiz2");

		const $ = global.jquery;
		// jQuery /////
		// 共通 アニメーション
		$(function () {
			// フェード
			$('.fade').addClass('start');
			// ボタン順番に表示
			$('.fade-turn').delay(1200).queue(function () {
				$('.fade-turn').find('li').each(function (index) {
					$(this).delay(index * 500).queue(function () {
						$(this).addClass('start');
					});
				});
			});
		});

		// トップ アニメーション
		$(function () {
			$('.top .title').addClass('start');
			$('.top .sorajiro').delay(1300).queue(function () {
				$(this).addClass('start').dequeue();
			});
			$('.top .announcer').delay(1500).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		// 正解・不正解 アニメーション
		$(function () {
			$('.right .result, .wrong .result').addClass('start');
			$('.right .answer, .right .text, .wrong .answer, .wrong .text').delay(1500).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		/////////////////

		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
	},
	methods: {
		getProfileTest: async function () {
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		},
		select: function (no) {
			this.answer = no;
			setTimeout(
				function () {
					const $ = global.jquery;
					$(function () {
						$('.right .result, .wrong .result').addClass('start');
						$('.right .answer, .right .text, .wrong .answer, .wrong .text').delay(1500).queue(function () {
							$(this).addClass('start').dequeue();
						});
					});
				},
				100
			);
		}
	}
};
</script>