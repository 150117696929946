<template>
	<div>
		<main v-if="top1 === 0">
			<section class="section select panel-red1 flame-red">
				<div class="text fade"><img src="@/assets/img/select_text.webp" width="350"
						alt="宇宙デブリが接近中！どうしよう？タップして選んで！">
				</div>
				<ul class="list fade-turn">
					<li class="item"><a href="#" class="btn"><img src="@/assets/img/select_btn1.webp" width="450"
								alt="１．避けて進む"></a></li>
					<li class="item"><a href="#" class="btn"><img src="@/assets/img/select_btn2.webp" width="450"
								alt="２．レーザーで止める"></a></li>
				</ul>
				<div class="sorajiro"><img src="@/assets/img/shooting_sorajiro.webp" width="320" alt="そらじろー"></div>
			</section>
		</main>
		<main v-if="top1 === 1">
			<section class="section select result panel-red1 flame-red">
				<div class="text"><img src="@/assets/img/wait_text.webp" width="280" alt="選択ありがとう！みんなが選んだ行動は…"></div>
				<ul class="list">
					<li class="item"><a class="btn flash"><img :src="flashImg1" width="450" alt="１．避けて進む"></a></li>
					<li class="item"><a class="btn inactive"><img src="@/assets/img/select_btn2.webp" width="450"
								alt="２．レーザーで止める"></a></li>
				</ul>
				<div class="sorajiro"><img src="@/assets/img/shooting_sorajiro.webp" width="320" alt="そらじろー"></div>
			</section>
		</main>
		<main v-if="top1 === 2">
			<section class="section select result panel-red1 flame-red">
				<div class="text"><img src="@/assets/img/wait_text.webp" width="280" alt="選択ありがとう！みんなが選んだ行動は…"></div>
				<ul class="list">
					<li class="item"><a class="btn inactive"><img src="@/assets/img/select_btn1.webp" width="450"
								alt="１．避けて進む"></a></li>
					<li class="item"><a class="btn flash"><img :src="flashImg2" width="450" alt="２．レーザーで止める"></a></li>
				</ul>
				<div class="sorajiro"><img src="@/assets/img/shooting_sorajiro.webp" width="320" alt="そらじろー"></div>
			</section>
		</main>
	</div>
</template>
<style scoped>
@import "../../css/style.css";
</style>
<script>
import MooxApi from '../../MooxApi';

import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');



export default {
	name: "Result",
	components: {

	},
	data() {
		return {
			choice: 0,
			top1: 0,
			votes: {},
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			theme: "b459edac-ce6d-4310-e3f2-08dc5de50fd2",
			flashImg1: require("@/assets/img/select_btn1_active.webp"),
			flashImg2: require("@/assets/img/select_btn2_active.webp"),
			assetSelectBtn1Active: require("@/assets/img/select_btn1_active.webp"),
			assetSelectBtn2Active: require("@/assets/img/select_btn2_active.webp"),
			assetSelectBtn1: require("@/assets/img/select_btn1.webp"),
			assetSelectBtn2: require("@/assets/img/select_btn2.webp"),
		};
	},
	async mounted() {
		console.log("moutend Result");

		const $ = global.jquery;
		// jQuery /////
		// 共通 アニメーション
		$(function () {
			// フェード
			$('.fade').addClass('start');
			// ボタン順番に表示
			$('.fade-turn').delay(1200).queue(function () {
				$('.fade-turn').find('li').each(function (index) {
					$(this).delay(index * 500).queue(function () {
						$(this).addClass('start');
					});
				});
			});
		});

		// トップ アニメーション
		$(function () {
			$('.top .title').addClass('start');
			$('.top .sorajiro').delay(1300).queue(function () {
				$(this).addClass('start').dequeue();
			});
			$('.top .announcer').delay(1500).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		// デブリ選択結果 アニメーション
		// $(function () {
		// 	let switchCount = 0;
		// 	const switchImage = function (element) {
		// 		const image = element.find('img'),
		// 			src = image.attr('src'),
		// 			newSrc = src.includes('_active') ? src.replace('_active', '') : src.replace('.webp', '_active.webp');
		// 		image.attr('src', newSrc);
		// 	};
		// 	const interval = setInterval(function () {
		// 		$('.flash').each(function () {
		// 			switchImage($(this));
		// 		});
		// 		switchCount++;
		// 		if (switchCount >= 8) {
		// 			clearInterval(interval);
		// 		}
		// 	}, 300);
		// });
		// ↑の実装を置き換え↓
		let switchCount = 0;
		let isFlashActive = false;
		const interval = setInterval(() => {
			isFlashActive = !isFlashActive;
			this.flashImg1 = isFlashActive ? this.assetSelectBtn1Active : this.assetSelectBtn1;
			this.flashImg2 = isFlashActive ? this.assetSelectBtn2Active : this.assetSelectBtn2;

			switchCount++;
			if (switchCount >= 8) {
				clearInterval(interval);
			}
		}, 300);

		// 正解・不正解 アニメーション
		$(function () {
			$('.right .result, .wrong .result').addClass('start');
			$('.right .answer, .right .text, .wrong .answer, .wrong .text').delay(1500).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		/////////////////

		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());

		// 投票結果を入れる
		const voteResult = await MooxApi.GetChoice(this.theme);
		console.log("voteResult↓");
		console.log(voteResult);
		this.top1 = parseInt(voteResult.top1);
		this.votes = voteResult.vote;
		this.$cookies.set('top1', voteResult.top1, MooxApi.cookieExpire);
	},
	methods: {
		getProfileTest: async function () {
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		},
		runJQuery: function () {
			const $ = global.jquery;
			// jQuery /////
			// 共通 アニメーション
			$(function () {
				// フェード
				$('.fade').addClass('start');
				// ボタン順番に表示
				$('.fade-turn').delay(1200).queue(function () {
					$('.fade-turn').find('li').each(function (index) {
						$(this).delay(index * 500).queue(function () {
							$(this).addClass('start');
						});
					});
				});
			});

			// トップ アニメーション
			$(function () {
				$('.top .title').addClass('start');
				$('.top .sorajiro').delay(1300).queue(function () {
					$(this).addClass('start').dequeue();
				});
				$('.top .announcer').delay(1500).queue(function () {
					$(this).addClass('start').dequeue();
				});
			});

			// デブリ選択集計 アニメーション
			$(function () {
				$('.wait .item').delay(1200).queue(function () {
					$(this).addClass('start').dequeue();
				});
			});

			// クイズ正解・不正解 アニメーション
			$(function () {
				$('.right .result, .wrong .result').addClass('start');
				$('.right .answer, .right .text, .wrong .answer, .wrong .text').delay(1500).queue(function () {
					$(this).addClass('start').dequeue();
				});
			});

			// 終了画面アニメーション
			$(function () {
				$('.end .questionnaire').delay(2300).queue(function () {
					$(this).addClass('start').dequeue();
				});
			});
			/////////////////

		},
	}
};
</script>