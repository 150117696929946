<template>
	<div>
		<main v-if="top1 === 1">
			<section class="section move panel-blue flame-blue">
				<div :class="{text:true, fade:true, start:isStartFade}"><img src="@/assets/img/move_text.webp" width="350" alt="よし！避けて進むよ！"></div>
				<div class="sorajiro"><img src="@/assets/img/move_sorajiro.webp" width="350" alt="そらじろー"></div>
			</section>
		</main>
		<main v-else>
			<section class="section shooting panel-red2">
				<div :class="{text:true, fade:true, start:isStartFade}"><img src="@/assets/img/shooting_text.webp" width="350" alt="宇宙デブリ シューティング"></div>
				<div class="sorajiro"><img src="@/assets/img/shooting_sorajiro.webp" width="320" alt="そらじろー"></div>
				<div class="laser"><img src="@/assets/img/laser.webp" width="600" alt="レーザー"></div>
			</section>
		</main>
	</div>
</template>
<style scoped>
@import "../../css/style.css";
</style>
<script>
import MooxApi from '../../MooxApi';

import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');

export default {
	name: "MoveShooting",
	components: {

	},
	data() {
		return {
			choice: 0,
			top1: 0,
			votes: {},
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			theme: "b459edac-ce6d-4310-e3f2-08dc5de50fd2",
			isStartFade: false,
		};
	},
	async mounted() {
		console.log("moutend Shooting");

		MooxApi.StartUpdate();

		if (this.$cookies.isKey('top1') && this.$cookies.get('top1')) {
			this.top1 = parseInt(this.$cookies.get('top1'));
		}
		// 投票結果を入れる
		if(this.top1 === 0){
			const voteResult = await MooxApi.GetChoice(this.theme);
			console.log("voteResult↓");
			console.log(voteResult);
			this.top1 = parseInt(voteResult.top1);
			this.votes = voteResult.vote;
			//後のページのためにcookieに保存する
			this.$cookies.set('top1', voteResult.top1, MooxApi.cookieExpire);
		}
		//「共通 アニメーション」の置き換え
		setTimeout(
			() => {
				this.isStartFade = true;
			}, 300
		)
		// this.runJQuery();
	},
	methods: {
		getProfileTest: async function () {
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		},
	}
};
</script>