<template>
	<main>
		<!-- レーザーで止める時は前が同じ画面なのでアニメーションさせない -->
        <section class="section shooting panel-red2" v-if="top1===2">
            <div class="text" style="transform: translate(-50%,0); opacity: 1;"><img src="@/assets/img/shooting_text.webp" width="350" alt="宇宙デブリ シューティング"></div>
            <div class="sorajiro"><img src="@/assets/img/shooting_sorajiro.webp" width="320" alt="そらじろー"></div>
            <div class="laser"><img src="@/assets/img/laser.webp" width="600" alt="レーザー"></div>
		</section>
		<section class="section shooting panel-red2" v-else>
			<div :class="{text:true, fade:true, start:isStartFade}"><img src="@/assets/img/shooting_text.webp" width="350" alt="宇宙デブリ シューティング"></div>
			<div class="sorajiro"><img src="@/assets/img/shooting_sorajiro.webp" width="320" alt="そらじろー"></div>
			<div class="laser"><img src="@/assets/img/laser.webp" width="600" alt="レーザー"></div>
		</section>
	</main>
</template>
<style scoped>
@import "../../css/style.css";
</style>
<script>
import MooxApi from '../../MooxApi';

import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');

export default {
	name: "Shooting",
	components: {

	},
	data() {
		return {
			choice: 0,
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			isStartFade: false,
			top1: 0,
		};
	},
	async mounted() {
		console.log("moutend Shooting");

		if (this.$cookies.isKey('top1') && this.$cookies.get('top1')) {
			this.top1 = parseInt(this.$cookies.get('top1'));
		}
		console.log(`hascookie ${this.$cookies.isKey('top1')}, top1:${this.$cookies.get('top1')}`);

		// 全体フェードアニメーションの大体処理
		this.isStartFade = true;


		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
	},
	methods: {
		getProfileTest: async function () {
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		}
	}
};
</script>