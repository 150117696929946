<template>
    <div class="wrapper">
        <div>
            トリガーテスト
        </div>
        <div>
            <router-link to="/sample_page">Page</router-link>
        </div>
        <div>
            <label>vehicleId</label>: <input v-model="vehicleId" size="40"><br>
            <label>token</label>: <input v-model="token" size="60"><br>
        </div>
        <div>
            <button @click="registerTrigger('start')">発報(start)</button><br>
            <button @click="registerTrigger('ChoiceSample')">発報(投票サンプル)</button><br>
            <br>
            <button @click="getOnTrigger('CentrairExpo2024 CentrairToExpo 01')">乗車発報(Centrair2023 Short 00)(GetOnトリガー)</button><br>
            <button @click="playTrigger('CentrairExpo2024 CentrairToExpo 1-2')">ツアー概要(セントレア)(1-2)(Playトリガー)</button><br>
            <button @click="registerTrigger('CentrairExpo2024 CentrairToExpo 1-2')">ツアー概要(セントレア)(1-2)乗車モードの発報なし</button><br>
            <button @click="registerTrigger('CentrairExpo2024 CentrairToExpo 1-3')">CM(1-3)</button><br>

            <br>
            <h2>SusHi Techページ確認</h2>
            <button @click="registerTrigger('SusHiTech2024 Soratabi Top')">待機ページ(トップ)(Top)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Soratabi Select')">登場演出(Select)</button><br>
            <!-- <button @click="registerTrigger('SusHiTech2024 Soratabi Wait')">集計中(Wait)</button><br> -->
            <button @click="registerTrigger('SusHiTech2024 Soratabi Result')">集計結果(Result)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Soratabi MoveShooting')">避けて進むタイトル(MoveShooting)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Soratabi Shooting')">連打タイトル(Shooting)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Soratabi ShootingVs')">連打演出(ShootingVs)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Soratabi Quiz1')">クイズ1(Quiz1)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Soratabi Quiz2')">クイズ2(Quiz2)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Soratabi End')">エンド/アンケートリンク(End)(投票状況リセット)</button><br>

            <h2>SusHi Techページ確認</h2>
            <button @click="getOnTrigger('SusHiTech2024 Soratabi 01')">GetOn発報(SusHiTech2024 Soratabi 01)</button><br>
            <button @click="playTrigger('SusHiTech2024 Soratabi 02')">Play発報(SusHiTech2024 Soratabi 02)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Soratabi 03')">発報(SusHiTech2024 Soratabi 03)</button><br>
            <button @click="getOffTrigger('SusHiTech2024 Soratabi 04')">GetOff(SusHiTech2024 Soratabi 04)</button><br>
            <br>
            <h2>SusHi Tech At Nihonbashiページ確認</h2>
            <button @click="getOnTrigger('SusHiTech2024 Nihonbashi 01')">GetOn発報(SusHiTech2024 Nihonbashi 01)</button><br>
            <button @click="playTrigger('SusHiTech2024 Nihonbashi 02')">Play発報(SusHiTech2024 Nihonbashi 02)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Nihonbashi 04')">発報(SusHiTech2024 Nihonbashi 04)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Nihonbashi 04.5')">発報(SusHiTech2024 Nihonbashi 04.5)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Nihonbashi 05')">発報(SusHiTech2024 Nihonbashi 05)</button><br>
            <button @click="registerTrigger('SusHiTech2024 Nihonbashi 06')">発報(SusHiTech2024 Nihonbashi 06)</button><br>
            <button @click="getOffTrigger('SusHiTech2024 Nihonbashi 07')">GetOff(SusHiTech2024 Nihonbashi 07)</button><br>
            <br>
            <button @click="resetTrigger()">投票リセット</button><br>
            <br>
            <button @click="registerTrigger('RedirectTest')">リダイレクトテスト</button><br>
            <button @click="registerTrigger('SeatInfomation')">座席情報</button><br>
            <br>
            <button @click="playTrigger('Test 01')">Play発報(Test 01)</button><br>
            <button @click="getOnTrigger('Test 02')">GetOn発報(Test 02)</button><br>
            <button @click="registerTrigger('Test 03')">発報(Test 03)</button><br>
            <button @click="getOffTrigger('Test 03')">GetOff(Test 03)</button><br>

            <!-- <button @click="removeCookie()">Cookie削除</button><br> -->
        </div>
        <div>
            {{ responseString }}
        </div>
    </div><!-- wrapper -->
</template>
  
<style></style>
  
<script>
// import Vue from 'vue'
// import VueCookies from 'vue-cookies'
import MooxApi from '../MooxApi'

// Vue.use(VueCookies, { expires: '60 * 60 * 6'})

export default {
    name: "TriggerTest",
    components: {

    },
    data() {
        return {
            triggers: [],
            responseString: "",
            vehicleId: "51bf221e-d480-4cef-a253-08dc22df0daa",
            token: "87a4baf0739370c7e64af4e8f6227ecc42a7354dc5c40f975142742eb1415d52",
        };
    },
    async mounted() {
        if(this.$route.query.vehicle){
            this.vehicleId = this.$route.query.vehicle;
        }
        if(this.$route.query.token){
            this.token = this.$route.query.token;
        }

        // トリガーの取得
        // await this.updateTrigger();
        MooxApi.OnUpdate = null;
        MooxApi.OnTrigger = null;
        MooxApi.OnLeave();

    },

    // curl -X POST https://tb-api-1.azurewebsites.net/api/v1/triggers 
    // -H "Content-Type: application/json" 
    // -H "vehicle: 9a83a71a-2f3b-4114-127f-08dbb9a12b00"
    // -H "token: e61056465a1cbed0fc6d6ffa8673f91ac4d7716214a042973f9b4b63fa2d2dd9"
    // -d '{"content":"start:2023-09-23 17:18:10"}' -v

    methods: {
        checkTrigger: async function () {
            const triggers = await MooxApi.GetTrigger();
            this.responseString = JSON.stringify(triggers);

        },
        // removeCookie: async function(){
        //     if(Vue.$cookies.isKey('seat')){
        //         Vue.$cookies.remomve('seat');
        //     }
        //     if(Vue.$cookies.isKey('vehicle')){
        //         Vue.$cookies.remomve('vehicle');
        //     }
        //     if(Vue.$cookies.isKey('token')){
        //         Vue.$cookies.remomve('token');
        //     }
        //     if(Vue.$cookies.isKey('user')){
        //         Vue.$cookies.remomve('user');
        //     }
        //     if(Vue.$cookies.isKey('secret')){
        //         Vue.$cookies.remomve('secret');
        //     }
        //     if(Vue.$cookies.isKey('choice')){
        //         Vue.$cookies.remomve('choice');
        //     }
        // },
        registerTrigger: async function(label){
            const apiEndpoint =  "https://api.tb.compass-web.jp";
            const uriTrigger =  "/api/v1/triggers";
            const now = new Date();
            // const seatId = "98a8d21c-8f71-47b8-3e25-08dbbd9d39ca";
            // const vehicleId = "7ed88f6d-b9b0-46a2-2095-08dbbdbe30c9";
            // const token = "123d516d5ca827f764b82472ffb0a316327866f60bd02e5c5ea5a0236837179b";
            // 常滑開発用

            const year = ( '0000' + now.getFullYear() ).slice( -4 );
            const mon = ( '00' + (now.getMonth()+1) ).slice( -2 );
            const day = ( '00' + now.getDate() ).slice( -2 );
            const hour = ( '00' + now.getHours() ).slice( -2 );
            const min = ( '00' + now.getMinutes() ).slice( -2 );
            const sec = ( '00' + now.getSeconds() ).slice( -2 );
            
            const body = {content:`${label}:${year}-${mon}-${day} ${hour}:${min}:${sec}`};
            console.log(JSON.stringify(body));
            try{
                const response = await fetch(apiEndpoint + uriTrigger,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "vehicle": this.vehicleId,
                            "token": this.token,
                        },
                        body: JSON.stringify(body),
                    });
                // console.log("response");
                // console.log(response.json());
                const responseText = await response.text();
                console.log(responseText);
                alert("発報しました。\n" + responseText);
            }
            catch(err){
                alert("エラーが発生しました。¥n" + err);
            }
        },
        resetTrigger: async function(){
            const label = "Centrair2023 Short";
            const apiEndpoint =  "https://api.tb.compass-web.jp";
            const uriTrigger =  "/api/v1/choices/reset";
            const now = new Date();

            const year = ( '0000' + now.getFullYear() ).slice( -4 );
            const mon = ( '00' + (now.getMonth()+1) ).slice( -2 );
            const day = ( '00' + now.getDate() ).slice( -2 );
            const hour = ( '00' + now.getHours() ).slice( -2 );
            const min = ( '00' + now.getMinutes() ).slice( -2 );
            const sec = ( '00' + now.getSeconds() ).slice( -2 );
            
            const body = {content:`${label}:${year}-${mon}-${day} ${hour}:${min}:${sec}`};
            console.log(JSON.stringify(body));
            try{
                const response = await fetch(apiEndpoint + uriTrigger,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "vehicle": this.vehicleId,
                            "token": this.token,
                        },
                        body: JSON.stringify(body),
                    });
                // console.log("response");
                // console.log(response.json());
                const responseText = await response.text();
                console.log(responseText);
                alert("リセットしました。\n" + responseText);
            }
            catch(err){
                alert("エラーが発生しました。\n" + err);
            }
        },
        getOnTrigger: async function(label){
            const apiEndpoint =  "https://api.tb.compass-web.jp";
            const uriTrigger =  "/api/v1/get-on";
            const now = new Date();

            const year = ( '0000' + now.getFullYear() ).slice( -4 );
            const mon = ( '00' + (now.getMonth()+1) ).slice( -2 );
            const day = ( '00' + now.getDate() ).slice( -2 );
            const hour = ( '00' + now.getHours() ).slice( -2 );
            const min = ( '00' + now.getMinutes() ).slice( -2 );
            const sec = ( '00' + now.getSeconds() ).slice( -2 );
            
            const body = {content:`${label}:${year}-${mon}-${day} ${hour}:${min}:${sec}`};
            console.log(JSON.stringify(body));
            try{
                const response = await fetch(apiEndpoint + uriTrigger,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "vehicle": this.vehicleId,
                            "token": this.token,
                        },
                        body: JSON.stringify(body),
                    });
                // console.log("response");
                // console.log(response.json());
                const responseText = await response.text();
                console.log(responseText);
                alert("リセットしました。\n" + responseText);
            }
            catch(err){
                alert("エラーが発生しました。\n" + err);
            }
        },
        playTrigger: async function(label){
            const apiEndpoint =  "https://api.tb.compass-web.jp";
            const uriTrigger =  "/api/v1/play";
            const now = new Date();

            const year = ( '0000' + now.getFullYear() ).slice( -4 );
            const mon = ( '00' + (now.getMonth()+1) ).slice( -2 );
            const day = ( '00' + now.getDate() ).slice( -2 );
            const hour = ( '00' + now.getHours() ).slice( -2 );
            const min = ( '00' + now.getMinutes() ).slice( -2 );
            const sec = ( '00' + now.getSeconds() ).slice( -2 );
            
            const body = {content:`${label}:${year}-${mon}-${day} ${hour}:${min}:${sec}`};
            console.log(JSON.stringify(body));
            try{
                const response = await fetch(apiEndpoint + uriTrigger,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "vehicle": this.vehicleId,
                            "token": this.token,
                        },
                        body: JSON.stringify(body),
                    });
                // console.log("response");
                // console.log(response.json());
                const responseText = await response.text();
                console.log(responseText);
                alert("プレイしました。\n" + responseText);
            }
            catch(err){
                alert("エラーが発生しました。\n" + err);
            }
        },
        getOffTrigger: async function(label){
            const apiEndpoint =  "https://api.tb.compass-web.jp";
            const uriTrigger =  "/api/v1/get-off";
            const now = new Date();

            const year = ( '0000' + now.getFullYear() ).slice( -4 );
            const mon = ( '00' + (now.getMonth()+1) ).slice( -2 );
            const day = ( '00' + now.getDate() ).slice( -2 );
            const hour = ( '00' + now.getHours() ).slice( -2 );
            const min = ( '00' + now.getMinutes() ).slice( -2 );
            const sec = ( '00' + now.getSeconds() ).slice( -2 );
            
            const body = {content:`${label}:${year}-${mon}-${day} ${hour}:${min}:${sec}`};
            console.log(JSON.stringify(body));
            try{
                const response = await fetch(apiEndpoint + uriTrigger,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "vehicle": this.vehicleId,
                            "token": this.token,
                        },
                        body: JSON.stringify(body),
                    });
                // console.log("response");
                // console.log(response.json());
                const responseText = await response.text();
                console.log(responseText);
                alert("降車トリガーを送りました。\n" + responseText);
            }
            catch(err){
                alert("エラーが発生しました。\n" + err);
            }
        }
    },
    
};
</script>