<template>
	<main>
        <section class="section top end">
            <div class="logo"><img src="@/assets/img/top_logo1.webp" width="100" alt="MOOX RIDE"></div>
			<div class="wrap fade">
				<div class="btn"><a href="https://forms.gle/UgT2do56GSUgxSFC7" target="_blank"><img src="@/assets/img/end_btn.webp" width="450" alt="アンケートはこちら"></a></div>
				<div class="text"><img src="@/assets/img/end_text.webp" width="300" alt="ご乗車ありがとう！アンケート回答者にはステッカーをプレゼント！"></div>
			</div>
			<div class="announcer floating"><img src="@/assets/img/top_announcer.webp" width="320" alt="アナウンサー"></div>
            <div class="sorajiro floating"><img src="@/assets/img/top_sorajiro.webp" width="300" alt="そらじろー"></div>
			<div class="logo2"><img src="@/assets/img/top_logo2.webp" width="400" alt="SORA TABI"></div>
		</section>
	</main>
</template>
<style scoped>
@import "../../css/style.css";
</style>
<script>
import MooxApi from '../../MooxApi';

import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');

export default {
	name: "End",
	components: {

	},
	data() {
		return {
			choice: 0,
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
		};
	},
	async mounted() {
		console.log("moutend End");

		const $ = global.jquery;
		// jQuery /////
		// 共通 アニメーション
		$(function () {
			// フェード
			$('.fade').addClass('start');
			// ボタン順番に表示
			$('.fade-turn').delay(1200).queue(function () {
				$('.fade-turn').find('li').each(function (index) {
					$(this).delay(index * 500).queue(function () {
						$(this).addClass('start');
					});
				});
			});
		});

		// トップ アニメーション
		$(function () {
			$('.top .title').addClass('start');
			$('.top .sorajiro').delay(1300).queue(function () {
				$(this).addClass('start').dequeue();
			});
			$('.top .announcer').delay(1500).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		// デブリ選択集計 アニメーション
		$(function () {
			$('.wait .item').delay(1200).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		// クイズ正解・不正解 アニメーション
		$(function () {
			$('.right .result, .wrong .result').addClass('start');
			$('.right .answer, .right .text, .wrong .answer, .wrong .text').delay(1500).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		// 終了画面アニメーション
		$(function(){
			$('.end .logo2').delay(2300).queue(function(){
				$(this).addClass('start').dequeue();
			});
		});
		/////////////////

		// 投票をリセット
		this.$cookies.set('choice', 0, MooxApi.cookieExpire);

		MooxApi.StartUpdate();
	},
	methods: {
		getProfileTest: async function () {
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		}
	}
};
</script>