<template>
	<div class="contents">

		<header class="header">
		</header>

		<div class="container_top">

			<section class="section01">
				<p class="topText01">みんなが選んだ行動は・・</p>
			</section>

		</div>


	</div>
</template>
<style scoped>
@import "../../css/style.css";
</style>
<script>
import MooxApi from '../../MooxApi';

import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');

export default {
	name: "Wait",
	components: {

	},
	data() {
		return {
			choice: 0,
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
		};
	},
	async mounted() {
		console.log("moutend WaitingResult");

		const $ = global.jquery;
        // jQuery /////
		// 共通 アニメーション
		$(function(){
			// フェード
			$('.fade').addClass('start');
			// ボタン順番に表示
			$('.fade-turn').delay(1200).queue(function(){
				$('.fade-turn').find('li').each(function(index){
					$(this).delay(index * 500).queue(function(){
						$(this).addClass('start');
					});
				});
			});
		});

		// トップ アニメーション
		$(function(){
			$('.top .title').addClass('start');
			$('.top .sorajiro').delay(1300).queue(function(){
				$(this).addClass('start').dequeue();
			});
			$('.top .announcer').delay(1500).queue(function(){
				$(this).addClass('start').dequeue();
			});
		});

		// 正解・不正解 アニメーション
		$(function(){
			$('.right .result, .wrong .result').addClass('start');
			$('.right .answer, .right .text, .wrong .answer, .wrong .text').delay(1500).queue(function(){
				$(this).addClass('start').dequeue();
			});
		});

        /////////////////

		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
	},
	methods: {
		getProfileTest: async function(){
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		}
	}
};
</script>