<template>
	<div>
		<main v-if="choice === 0 && isFix == false">
			<section class="section select panel-red1 flame-red">
				<div class="text fade"><img src="@/assets/img/select_text.webp" width="350"
						alt="宇宙デブリが接近中！どうしよう？タップして選んで！">
				</div>
				<ul class="list fade-turn">
					<li class="item"><a href="#" class="btn" @click="vote(1)"><img src="@/assets/img/select_btn1.webp"
								width="450" alt="１．避けて進む"></a></li>
					<li class="item"><a href="#" class="btn" @click="vote(2)"><img src="@/assets/img/select_btn2.webp"
								width="450" alt="２．レーザーで止める"></a></li>
				</ul>
				<div class="sorajiro"><img src="@/assets/img/shooting_sorajiro.webp" width="320" alt="そらじろー"></div>
			</section>
		</main>
		<main v-if="choice === 1 && isFix === false">
			<section class="section select wait panel-red1 flame-red">
				<div class="text fade"><img src="@/assets/img/wait_text.webp" width="280" alt="選択ありがとう！みんなが選んだ行動は…">
				</div>
				<div class="sorajiro"><img src="@/assets/img/shooting_sorajiro.webp" width="320" alt="そらじろー"></div>
			</section>
		</main>
		<main v-if="choice === 2 && isFix === false">
			<section class="section select wait panel-red1 flame-red">
				<div class="text fade"><img src="@/assets/img/wait_text.webp" width="280" alt="選択ありがとう！みんなが選んだ行動は…">
				</div>
				<div class="sorajiro"><img src="@/assets/img/shooting_sorajiro.webp" width="320" alt="そらじろー"></div>
			</section>
		</main>
		<main v-if="isFix === true">
			<section class="section select wait panel-red1 flame-red">
				<div class="text fade"><img src="@/assets/img/wait_text.webp" width="280" alt="選択ありがとう！みんなが選んだ行動は…">
				</div>
				<div class="sorajiro"><img src="@/assets/img/shooting_sorajiro.webp" width="320" alt="そらじろー"></div>
			</section>
		</main>
	</div>
</template>
<style scoped>
@import "../../css/style.css";
</style>
<script>
import MooxApi from '../../MooxApi';

import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');

export default {
	name: "Select",
	components: {

	},
	data() {
		return {
			choice: 0,
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			themeId: "b459edac-ce6d-4310-e3f2-08dc5de50fd2",
			isFix: false,
		};
	},
	async mounted() {
		console.log("moutend SushitechVote");

		this.runJQuery();
		if (this.$cookies.get('choice')) {
			this.choice = parseInt(this.$cookies.get('choice'));
		}
		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
		setTimeout(() =>{
			// 25秒でfix(読み込みの誤差を考え少し速くする)。少し無理やりな実装
			this.isFix = true;
			this.runJQuery();
		}, 19500);
	},
	methods: {
		getProfileTest: async function () {
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		},
		runJQuery: function () {
			const $ = global.jquery;
			// jQuery /////
			// 共通 アニメーション
			$(function () {
				// フェード
				$('.fade').addClass('start');
				// ボタン順番に表示
				$('.fade-turn').delay(1200).queue(function () {
					$('.fade-turn').find('li').each(function (index) {
						$(this).delay(index * 500).queue(function () {
							$(this).addClass('start');
						});
					});
				});
			});

			// トップ アニメーション
			$(function () {
				$('.top .title').addClass('start');
				$('.top .sorajiro').delay(1300).queue(function () {
					$(this).addClass('start').dequeue();
				});
				$('.top .announcer').delay(1500).queue(function () {
					$(this).addClass('start').dequeue();
				});
			});

			// デブリ選択集計 アニメーション
			$(function () {
				$('.wait .item').delay(1200).queue(function () {
					$(this).addClass('start').dequeue();
				});
			});

			// クイズ正解・不正解 アニメーション
			$(function () {
				$('.right .result, .wrong .result').addClass('start');
				$('.right .answer, .right .text, .wrong .answer, .wrong .text').delay(1500).queue(function () {
					$(this).addClass('start').dequeue();
				});
			});

			// 終了画面アニメーション
			$(function () {
				$('.end .questionnaire').delay(2300).queue(function () {
					$(this).addClass('start').dequeue();
				});
			});
			/////////////////

		},
		vote: async function (ch) {
			console.log(`vote ${ch}`);
			if (this.choice !== 0) {
				alert("投票済みです。");
				return;
			}
			const result = await MooxApi.PostChoice(this.themeId, ch);
			console.log(`${result}: choice: ${ch}`);
			if (result === false) {
				alert("投票に失敗しました。");
			}
			else if (result === true) {
				this.choice = ch;
				this.$cookies.set('choice', ch, MooxApi.cookieExpire);
			}

			this.runJQuery();
		}
	}
};
</script>