<template>
	<main>
        <section class="section top">
            <div class="logo"><img src="@/assets/img/top_logo1.webp" width="100" alt="MOOX RIDE"></div>
            <h1 class="title"><img src="@/assets/img/top_logo2.webp" width="400" alt="SORA TABI"></h1>
            <div class="announcer floating"><img src="@/assets/img/top_announcer.webp" width="320" alt="アナウンサー"></div>
            <div class="sorajiro floating"><img src="@/assets/img/top_sorajiro.webp" width="300" alt="そらじろー"></div>
		</section>
	</main>
</template>
<style scoped>
@import "../css/style.css";
</style>
<script>
import MooxApi from '../MooxApi';

import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');

export default {
    name: "Home",
    components: {

    },
    data() {
        return {
            seatId: null,
            vehicleId: null,
            token: null,
            // triggers: [],
            // responseString: "",
        };
    },
    async mounted() {
        console.log("moutend Home");

		const $ = global.jquery;
        // jQuery /////
		// 共通 アニメーション
		$(function(){
			// フェード
			$('.fade').addClass('start');
			// ボタン順番に表示
			$('.fade-turn').delay(1200).queue(function(){
				$('.fade-turn').find('li').each(function(index){
					$(this).delay(index * 500).queue(function(){
						$(this).addClass('start');
					});
				});
			});
		});

		// トップ アニメーション
		$(function(){
			$('.top .title').addClass('start');
			$('.top .sorajiro').delay(1300).queue(function(){
				$(this).addClass('start').dequeue();
			});
			$('.top .announcer').delay(1500).queue(function(){
				$(this).addClass('start').dequeue();
			});
		});

		// 正解・不正解 アニメーション
		$(function(){
			$('.right .result, .wrong .result').addClass('start');
			$('.right .answer, .right .text, .wrong .answer, .wrong .text').delay(1500).queue(function(){
				$(this).addClass('start').dequeue();
			});
		});
        /////////////////


        MooxApi.StartUpdate();
    },
    methods: {
    }
};
</script>