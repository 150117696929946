import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/home.vue'
import Page1 from '../views/page.vue'
import Choice from '../views/choice.vue'
import TriggerTest from '../views/TriggerTest.vue'

import SushitechTop from '../views/sushitech/top.vue'
import Select from '../views/sushitech/select.vue'
import Wait from '../views/sushitech/wait.vue'
import Result from '../views/sushitech/result.vue'
import ShootingVs from '../views/sushitech/shooting_vs.vue'
import Shooting from '../views/sushitech/shooting.vue'
import MoveShooting from '../views/sushitech/move_shooting.vue'
import Quiz1 from '../views/sushitech/quiz1.vue'
import Quiz2 from '../views/sushitech/quiz2.vue'
import End from '../views/sushitech/end.vue'


// import VueGtag from 'vue-gtag'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      // meta: { title: 'ホーム' },
      props: true
    },
    {
      path: '/page',
      name: 'Page1',
      component: Page1,
      meta: { title: 'サンプルページ' },
      props: true
    },
    {
      path: '/choice',
      name: 'Choice',
      component: Choice,
      meta: { title: '投票サンプルページ' },
      props: true
    },
    {
      path: '/dev/trigger',
      name: 'TriggerTest',
      component: TriggerTest,
      meta: { title: '発報' },
      props: true
    },
    {
      path: '/sushitech/top',
      name: 'SushitechTop',
      component: SushitechTop,
      meta: { title: 'TOP' },
      props: true
    },
    {
      path: '/sushitech/select',
      name: 'Select',
      component: Select,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sushitech/wait',
      name: 'Wait',
      component: Wait,
      meta: { title: 'MOOX' },
      props: true
    },
    {
      path: '/sushitech/result',
      name: 'Result',
      component: Result,
      meta: { title: 'RESULT' },
      props: true
    },
    {
      path: '/sushitech/move_shooting',
      name: 'MoveShooting',
      component: MoveShooting,
      meta: { title: 'SHOOTING' },
      props: true
    },
    {
      path: '/sushitech/shooting_vs',
      name: 'ShootingVs',
      component: ShootingVs,
      meta: { title: 'SHOOTING' },
      props: true
    },
    {
      path: '/sushitech/shooting',
      name: 'Shooting',
      component: Shooting,
      meta: { title: 'SHOOTING' },
      props: true
    },
    {
      path: '/sushitech/quiz1',
      name: 'Quiz1',
      component: Quiz1,
      meta: { title: 'QUIZ1' },
      props: true
    },
    {
      path: '/sushitech/quiz2',
      name: 'Quiz2',
      component: Quiz2,
      meta: { title: 'QUIZ2' },
      props: true
    },
    {
      path: '/sushitech/end',
      name: 'End',
      component: End,
      meta: { title: 'END' },
      props: true
    },
  ],
  scrollBehavior() {
    return { x:0, y: 0 };
  },
});

// Vueでvue-gtagを利用してGoogle Analytics4(GA4)の計測を行う方法
// https://kagasu.hatenablog.com/entry/2022/07/27/112623
// TODO: Googleタグを取得して設定する
// Vue.use(VueGtag, {
//   config: {
//     id: ''
//   }
// }, router);

const DEFAULT_TITLE = 'MOOX RIDE'

router.afterEach((to) => {
  const title = to.meta.title ? `${ to.meta.title }|${ DEFAULT_TITLE }` : DEFAULT_TITLE
  document.title = title;
});


export default router;
