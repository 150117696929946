<template>
	<main>
		<!-- レーザーで止める時は前が同じ画面なのでアニメーションさせない -->
		<section class="section shooting panel-red2" v-if="isShooting === false">
			<div class="text" style="transform: translate(-50%,0); opacity: 1;"><img
					src="@/assets/img/shooting_text.webp" width="350" alt="宇宙デブリ シューティング"></div>
			<div class="sorajiro"><img src="@/assets/img/shooting_sorajiro.webp" width="320" alt="そらじろー"></div>
			<div class="laser"><img src="@/assets/img/laser.webp" width="600" alt="レーザー"></div>
		</section>
		<section class="section vs panel-red1" v-else>
			<div class="text1 fade"><img src="@/assets/img/vs_text1.webp" width="350" alt="みんなの力で宇宙デブリを止めよう スマホを連続タップ">
			</div>
			<div class="btn" id="hit-btn" @click="clickHitButton"><img :src="orgImage1" width="400" alt="連打ボタン"></div>
			<div class="text2"><img src="@/assets/img/vs_text2.webp" width="200" alt="レーザー発射"></div>
			<div class="laser" id="hit-laser"><img :src="orgImage2" width="600" alt="レーザー"></div>
		</section>
	</main>
</template>
<style scoped>
@import "../../css/style.css";
</style>
<script>
import MooxApi from '../../MooxApi';

import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');

export default {
	name: "ShootingVs",
	components: {

	},
	data() {
		return {
			choice: 0,
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
			orgImage1: require("@/assets/img/hit_btn.webp"),
			orgImage2: require("@/assets/img/laser.webp"),
			assetHitBtnOn: require("@/assets/img/hit_btn_on.webp"),
			assetLaserOn: require("@/assets/img/laser_on.webp"),
			assetHitBtn: require("@/assets/img/hit_btn.webp"),
			assetLaser: require("@/assets/img/laser.webp"),
			isShooting: false,
			top1: 0,
		};
	},
	async mounted() {
		console.log("moutend ShootingVs");

		if (this.$cookies.isKey('top1') && this.$cookies.get('top1')) {
			console.log("from cookie");
			this.top1 = parseInt(this.$cookies.get('top1'));
		}
		// 投票結果を入れる
		if (this.top1 === 0) {
			console.log("from api");
			const voteResult = await MooxApi.GetChoice(this.theme);
			this.top1 = parseInt(voteResult.top1);
		}
		console.log(`top1:${this.top1} `);

		if (this.top1 === 1) {
			console.log("delay fade animation");
			setTimeout(() => {
				this.isShooting = true;
				// 共通 アニメーション
				$(function () {
					// フェード
					$('.fade').addClass('start');
					// ボタン順番に表示
					$('.fade-turn').delay(1200).queue(function () {
						$('.fade-turn').find('li').each(function (index) {
							$(this).delay(index * 500).queue(function () {
								$(this).addClass('start');
							});
						});
					});
				});
			}, 2000);
		}
		else {
			console.log("not fade animation");
			this.isShooting = true;
		}

		const $ = global.jquery;
		// jQuery /////
		// 共通 アニメーション
		$(function () {
			// フェード
			$('.fade').addClass('start');
			// ボタン順番に表示
			$('.fade-turn').delay(1200).queue(function () {
				$('.fade-turn').find('li').each(function (index) {
					$(this).delay(index * 500).queue(function () {
						$(this).addClass('start');
					});
				});
			});
		});

		// トップ アニメーション
		$(function () {
			$('.top .title').addClass('start');
			$('.top .sorajiro').delay(1300).queue(function () {
				$(this).addClass('start').dequeue();
			});
			$('.top .announcer').delay(1500).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		// デブリ選択集計 アニメーション
		$(function () {
			$('.wait .item').delay(1200).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		// // 連打ボタン動作
		// $(function () {
		// 	const image1 = $('#hit-btn img'),
		// 		orgImageSrc1 = image1.attr('src'),
		// 		onImageSrc1 = orgImageSrc1.replace('.webp', '_on.webp'),
		// 		image2 = $('#hit-laser img'),
		// 		orgImageSrc2 = image2.attr('src'),
		// 		onImageSrc2 = orgImageSrc2.replace('.webp', '_on.webp');
		// 	$('#hit-btn').on('click', function () {
		// 		image1.attr('src', onImageSrc1);
		// 		image2.attr('src', onImageSrc2);
		// 		image1.delay(100).queue(function () {
		// 			image1.attr('src', orgImageSrc1).dequeue();
		// 			image2.attr('src', orgImageSrc2).dequeue();
		// 		});
		// 	});
		// });

		// クイズ正解・不正解 アニメーション
		$(function () {
			$('.right .result, .wrong .result').addClass('start');
			$('.right .answer, .right .text, .wrong .answer, .wrong .text').delay(1500).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		// 終了画面アニメーション
		$(function () {
			$('.end .questionnaire').delay(2300).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		/////////////////

		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
	},
	methods: {
		clickHitButton: function () {
			this.orgImage1 = this.assetHitBtnOn;
			this.orgImage2 = this.assetLaserOn;
			setTimeout(() => {
				this.orgImage1 = this.assetHitBtn;
				this.orgImage2 = this.assetLaser;
			}, 100);
		},
		getProfileTest: async function () {
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		}
	}
};
</script>