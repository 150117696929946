<template>
	<main>
        <section class="section top">
            <div class="logo"><img src="@/assets/img/top_logo1.webp" width="100" alt="MOOX RIDE"></div>
            <h1 class="title"><img src="@/assets/img/top_logo2.webp" width="400" alt="SORA TABI"></h1>
            <div class="announcer floating"><img src="@/assets/img/top_announcer.webp" width="320" alt="アナウンサー"></div>
            <div class="sorajiro floating"><img src="@/assets/img/top_sorajiro.webp" width="300" alt="そらじろー"></div>
		</section>
	</main>
</template>
<style scoped>
@import "../../css/style.css";
</style>
<script>
import MooxApi from '../../MooxApi';

import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');

export default {
	name: "SushitechTop",
	components: {

	},
	data() {
		return {
			choice: 0,
			seatId: null,
			vehicleId: null,
			token: null,
			destination: "",
			seat: "",
		};
	},
	async mounted() {
		console.log("moutend SushitechTop");

		const $ = global.jquery;
		// jQuery /////
		// 共通 アニメーション
		$(function () {
			// フェード
			$('.fade').addClass('start');
			// ボタン順番に表示
			$('.fade-turn').delay(1200).queue(function () {
				$('.fade-turn').find('li').each(function (index) {
					$(this).delay(index * 500).queue(function () {
						$(this).addClass('start');
					});
				});
			});
		});

		// トップ アニメーション
		$(function () {
			$('.top .title').addClass('start');
			$('.top .sorajiro').delay(1300).queue(function () {
				$(this).addClass('start').dequeue();
			});
			$('.top .announcer').delay(1500).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});

		// // デブリ選択結果 アニメーション
		// $(function () {
		// 	let switchCount = 0;
		// 	const switchImage = function (element) {
		// 		const image = element.find('img'),
		// 			src = image.attr('src'),
		// 			newSrc = src.includes('_active') ? src.replace('_active', '') : src.replace('.webp', '_active.webp');
		// 		image.attr('src', newSrc);
		// 	};
		// 	const interval = setInterval(function () {
		// 		$('.flash').each(function () {
		// 			switchImage($(this));
		// 		});
		// 		switchCount++;
		// 		if (switchCount >= 8) {
		// 			clearInterval(interval);
		// 		}
		// 	}, 300);
		// });


		// 正解・不正解 アニメーション
		$(function () {
			$('.right .result, .wrong .result').addClass('start');
			$('.right .answer, .right .text, .wrong .answer, .wrong .text').delay(1500).queue(function () {
				$(this).addClass('start').dequeue();
			});
		});
		/////////////////

		MooxApi.StartUpdate();
		console.log(MooxApi.GetRoute());
	},
	methods: {
		getProfileTest: async function () {
			const profile = await MooxApi.GetProfileFromApi();
			alert("GET " + JSON.stringify(profile));
			console.log(profile);
		}
	}
};
</script>